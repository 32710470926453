import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Tooltip } from 'react-tippy'
import { Field, FieldArray } from 'redux-form'
import { compose } from 'redux'
import { size, get, map, head } from 'lodash'

// atoms
import { connect } from 'react-redux'
import ReasonSelector from '../../../atoms/generics/ReasonSelector'
import ConfirmPreferredValue from '../../../atoms/generics/ConfirmPreferredValue'
import TextInputField from '../../../atoms/TextInputField'
import CheckboxField from '../../../atoms/CheckboxField'

// utils
import { createSuhlasDTO, formatOriginalSuhlas, formatSuhlas, isConfirmValid } from '../../../utils/suhlas'
import { DEFINICIA_TYP, SUHLAS_HODNOTA } from '../../../utils/enums'
import { EDIT_MODE, COLUMNS_COUNT } from '../../../containers/GenericUkon/genericUkonConfig'
import { formatFormValue } from '../../../utils/form'
import Permissions, { PERMISSIONS } from '../../../utils/permissionsHoc'
import { isEmail } from '../../../utils/email'

// resources
import addCrossIcon from '../../../resources/img/icons/add-cross.svg'

class ContactEmailField extends React.Component {
	static propTypes = {
		field: PropTypes.string,
		change: PropTypes.func.isRequired,
		dispatch: PropTypes.func.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape().isRequired,
		originalSuhlas: PropTypes.shape().isRequired,
		newSuhlas: PropTypes.shape(),
		onChangeSuhlas: PropTypes.func,
		uplatnitEfaktury: PropTypes.bool,
		nesuhlasyKontakty: PropTypes.shape(),
		aktualnostEmail: PropTypes.string,
		originalAktualnostEmail: PropTypes.string,
		disabledEfakturyComponent: PropTypes.bool
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		const { value, onChangeSuhlas, aktualnostEmail } = this.props

		if (!(onChangeSuhlas instanceof Function)) {
			return
		}

		if (isEmail(value) && isConfirmValid(aktualnostEmail)) {
			onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS, null, null, aktualnostEmail))
		}
	}

	componentDidUpdate(prevProps) {
		const { dispatch, field, value, change, onChangeSuhlas } = this.props

		if (value == '') {
			dispatch(change(field, null))
		}

		if (value != get(prevProps, 'value')) {
			if (isEmail(value)) {
				onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS, null))
			} else {
				onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.NAMIETKA, null))
			}
		}
	}

	onChangeGlobalConfirm = (value) => {
		const { onChangeSuhlas, aktualnostEmail } = this.props
		if (value) {
			onChangeSuhlas(createSuhlasDTO(SUHLAS_HODNOTA.SUHLAS, null))
		} else if (!aktualnostEmail) {
			onChangeSuhlas(null)
		}
	}

	renderEFaktury = ({ fields }) => {
		const { t } = this.props

		return (
			<>
				{fields.map(this.renderSubFields)}
				{size(fields) < 4 && (
					<button className='button small noselect' type='button' data-type='outline' data-color='blue' onClick={() => fields.push({})}>
						{t('translation:ZmluvneUcty.Pridať email')}
					</button>
				)}
			</>
		)
	}

	renderSubFields = (parent, index, fields) => {
		const { t } = this.props

		return (
			<div key={index} style={{ position: 'relative', marginBottom: '10px' }}>
				<img className='minus-field-icon' onClick={() => fields.remove(index)} src={addCrossIcon} />
				<div style={{ width: 'calc(100% - 30px)' }}>
					<Field name={`${parent}.email`} component={TextInputField} placeholder={t('translation:ZmluvneUcty.Zadajte kontaktný email')} />
				</div>
			</div>
		)
	}

	getEditModeContent = (originalNonPreferredValue, newNonPreferredValue, nesuhlasDovod) => {
		const { field, originalValue, value, originalSuhlas, newSuhlas, onChangeSuhlas, uplatnitEfaktury, disabledEfakturyComponent, t, aktualnostEmail } =
			this.props

		const confirmed = isConfirmValid(aktualnostEmail)

		const reasonOptions = map(nesuhlasDovod, (option) => {
			return {
				value: option.id,
				label: option.dovod
			}
		})

		let suhlasContent
		if (newNonPreferredValue) {
			let reasonDovod = null
			if (newSuhlas && newSuhlas.hodnota == SUHLAS_HODNOTA.NAMIETKA && !confirmed) {
				if (newSuhlas.nesuhlasDovodIny) {
					reasonDovod = newSuhlas.nesuhlasDovodIny
				} else if (newSuhlas.nesuhlasDovod && newSuhlas.nesuhlasDovod.id) {
					reasonDovod = newSuhlas.nesuhlasDovod.id
				}
			}
			suhlasContent = <ReasonSelector selectOptions={reasonOptions} onChange={onChangeSuhlas} value={reasonDovod} reasonIsNotActual={!confirmed} />
		} else if (!originalNonPreferredValue && originalValue == value) {
			suhlasContent = <ConfirmPreferredValue onChange={onChangeSuhlas} required={confirmed === false} confirmed={confirmed} />
		}

		const wrapper = <div style={{ marginTop: '5px' }}>{suhlasContent}</div>

		let uplatnitEfakturyComponent = (
			<Field
				disabled={disabledEfakturyComponent}
				name={'uplatnitEfaktury'}
				component={CheckboxField}
				onChange={this.onChangeGlobalConfirm}
				label={t('translation:Common.Uplatniť e-faktúru na všetkých ZÚ')}
			/>
		)
		if (disabledEfakturyComponent) {
			uplatnitEfakturyComponent = (
				<Tooltip
					html={<span>{t('translation:ObchodnyPartner.Pre aktiváciu e-faktúry musíte nastaviť kontaktný email')}</span>}
					position='left'
					trigger='mouseenter'
					theme='light'
				>
					{uplatnitEfakturyComponent}
				</Tooltip>
			)
		}

		return (
			<tr className={cx({ 'field-has-invalid-pattern': newNonPreferredValue || !confirmed })}>
				<td>
					<strong>{t('components:EditContactInfo.Kontaktný email')}</strong>
				</td>
				<td>
					<span style={{ verticalAlign: 'sub' }}>{originalValue}&nbsp;</span>
					{originalNonPreferredValue && (
						<Tooltip html={<span>{t('translation:Common.Nedoporučené nastavenie')}</span>} position='left' trigger='mouseenter' theme='light'>
							<div className='info-icon' style={{ float: 'right', top: '-15px' }} />
						</Tooltip>
					)}
					<p>{formatOriginalSuhlas(originalNonPreferredValue, originalSuhlas)}</p>
				</td>
				<td>
					<Field name={field} component={TextInputField} placeholder={t('components:EditContactInfo.Zadajte kontaktný email')} />
					{/* <TextInputField placeholder={t('components:EditContactInfo.Zadajte kontaktný email')} {...originalProps} /> */}
					{wrapper}
					<br />
					<Permissions
						allowed={[PERMISSIONS.UKON_UDAJE_ZU]}
						render={(hasPerm) => {
							if (hasPerm) {
								return (
									<>
										{uplatnitEfaktury && (
											<>
												<div>
													<FieldArray name='eFaktury' component={this.renderEFaktury} />
												</div>
												<br />
											</>
										)}
										{uplatnitEfakturyComponent}
									</>
								)
							}

							return (
								<Tooltip
									html={<span>{t('translation:Common.Na vykonanie akcie nemáte potrebné oprávnenia')}</span>}
									position='left'
									trigger='mouseenter'
									theme='light'
								>
									<Field
										name='uplatnitEfaktury'
										component={CheckboxField}
										disabled={true}
										onChange={this.onChangeGlobalConfirm}
										label={t('translation:Common.Uplatniť e-faktúru na všetkých ZÚ')}
									/>
								</Tooltip>
							)
						}}
					/>
				</td>
			</tr>
		)
	}

	render() {
		const {
			t,
			editMode,
			columnsCount = COLUMNS_COUNT.THREE,
			originalValue,
			value,
			originalSuhlas,
			newSuhlas,
			nesuhlasyKontakty,
			originalAktualnostEmail,
			aktualnostEmail
		} = this.props

		const originalNonPreferredValue = !isEmail(originalValue)
		const newNonPreferredValue = !isEmail(value)

		const nesuhlasDovod = head(nesuhlasyKontakty.data?.definicie?.filter((definicia) => definicia.id === DEFINICIA_TYP.KONTAKTY_EMAIL))?.nesulasy

		let content = (
			<td>
				<strong>{t('components:EditContactInfo.Kontaktný email')}</strong>
			</td>
		)

		if (columnsCount == COLUMNS_COUNT.THREE) {
			if (editMode == EDIT_MODE.EDIT) {
				content = this.getEditModeContent(originalNonPreferredValue, newNonPreferredValue, nesuhlasDovod)
			} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
				let formattedFormValue = formatFormValue(value, originalValue)
				content = (
					<tr>
						{content}
						<td>
							<p>{originalValue}</p>
							<p>{formatOriginalSuhlas(originalNonPreferredValue, originalSuhlas)}</p>
						</td>
						<td>
							<p>{formattedFormValue}</p>
							<p>{formatSuhlas(newNonPreferredValue, newSuhlas, originalSuhlas, nesuhlasDovod, originalAktualnostEmail, aktualnostEmail)}</p>
						</td>
					</tr>
				)
			}
		}

		return content
	}
}

const mapStateToProps = (state) => ({
	nesuhlasyKontakty: get(state, 'ukonEditOp.nesuhlasyKontakty')
})

export default compose(withTranslation('components'), connect(mapStateToProps, null))(ContactEmailField)
