import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { get, isEmpty, forEach, size } from 'lodash'
import cx from 'classnames'

// atoms
import { SelectField, UploadField } from '../../../atoms'

// components
import DefaultModal from '../../Modals/DefaultModal'

// config
import { EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { deleteReq } from '../../../utils/request'

class DokumentyField extends React.Component {
	static propTypes = {
		label: PropTypes.string,
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		dokumentTyp: PropTypes.shape(),
		dokumentDruhy: PropTypes.shape(),
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		povinny: PropTypes.bool.isRequired,
		meta: PropTypes.shape({
			touched: PropTypes.bool,
			error: PropTypes.string
		}).isRequired
	}

	state = {
		druhDokumentuModal: {
			visible: false,
			index: null
		}
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (dokumenty) => {
		const { povinny, t } = this.props

		if (povinny && isEmpty(dokumenty)) {
			return t('translation:Common.validate.Dokumenty sú povinné')
		}
		if (!isEmpty(dokumenty)) {
			forEach(dokumenty, (document) => {
				if (!get(document, 'dataAsBase64')) {
					return t('translation:Common.validate.Neplatný dokument', {
						nazovDokumentu: get(document, 'name')
					})
				}
			})
		}
	}

	onChangeDokumenty = (e, newFiles, originalFiles) => {
		const { field, dokumentDruhy } = this.props

		if (!field) {
			return
		}

		if (isEmpty(dokumentDruhy)) {
			return
		}

		if (size(newFiles) >= size(originalFiles)) {
			this.setState({
				druhDokumentuModal: {
					visible: true,
					index: size(newFiles) - 1
				}
			})
		}
	}

	// TODO: Ak je nahraný súbor, umožniť jeho odstránenie. Aktuálne komponent DropZoneField neumožňuje odstránenie súboru po nahratí.
	onDeleteUploadedFile = async (fileID) => {
		const { ukonNovy } = this.props

		try {
			await deleteReq(`/api/v0/ukony/${get(ukonNovy, 'id')}/dokumenty/${fileID}`)
		} catch (e) {
			/* eslint-disable no-console */
			console.log(e)
		}
	}

	render() {
		const { label, field, editMode, value, t, dokumentTyp, dokumentDruhy, meta } = this.props
		const { druhDokumentuModal } = this.state

		const dokumentDruhOptions = dokumentDruhy?.map((DokumentDruhDTO) => {
			const dokumentDruh = DokumentDruhDTO?.dokumentDruh

			return {
				label: dokumentDruh.nazov,
				value: dokumentDruh
			}
		})

		if (editMode == EDIT_MODE.LOOKUP || editMode == EDIT_MODE.CREATE || editMode == EDIT_MODE.EDIT) {
			return (
				<>
					{druhDokumentuModal.visible && (
						<DefaultModal
							modalTitle={t('components:GenericFields.Zvoľte typ vstupného dokumentu')}
							modalContent={
								<Field
									name={`${field}[${druhDokumentuModal.index}].dokumentDruh`}
									component={SelectField}
									options={dokumentDruhOptions}
									isSearchable={false}
									isDisabled={isEmpty(dokumentDruhOptions)}
									classNamePrefix='react-select'
								/>
							}
							leftButton={{
								onClick: () => {
									this.setState({
										druhDokumentuModal: {
											visible: false,
											index: null
										}
									})
								},
								text: t('translation:Common.Pokračovať'),
								disabled: isEmpty(value)
							}}
							visible
						/>
					)}
					<div className={cx('generic-field', { 'danger-box': meta?.error })}>
						<Field
							labelText={label || t('components:GenericFields.Vstupné dokumenty')}
							name={field}
							component={UploadField}
							onChange={this.onChangeDokumenty}
							validate={this.validate}
							multiple={false}
							onDelete={this.onDeleteUploadedFile}
							placeholder={t('components:GenericFields.Klikni alebo presuň súbory pre nahratie')}
							dokumentTyp={dokumentTyp}
						/>
					</div>
				</>
			)
		}

		return null
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(null, mapDispatchToProps))(DokumentyField)
