import { get, isEmpty } from 'lodash'
import { DOCUMENT_KEYS, DOKUMENT_TYP } from './enums'
import { postReq } from './request'

export const parseFileNameAndUrl = (signedPdf, templatePdf) => {
	if (get(signedPdf, 'data')) {
		return {
			fileName: get(signedPdf, 'data.name', ''),
			documentUrl: `data:${get(signedPdf, 'data.type')};base64,${get(signedPdf, 'data.dataAsBase64')}`
		}
	}
	if (get(templatePdf, 'data')) {
		return {
			fileName: get(templatePdf, 'data.name', ''),
			documentUrl: `data:application/pdf;base64,${get(templatePdf, 'data.dataAsBase64')}`
		}
	}
	return {
		fileName: '',
		documentUrl: '#'
	}
}

export const parseDocumentUrl = (document) => {
	if (document) {
		return `data:${get(document, 'type')};base64,${get(document, 'dataAsBase64')}`
	}
	return ''
}

export const parseDocuments = (parsedDocuments, document) => {
	switch (document?.typ?.id) {
		case DOKUMENT_TYP.VSTUPNY:
			return (parsedDocuments = {
				...parsedDocuments,
				[DOCUMENT_KEYS.VSTUPNY]: [...get(parsedDocuments, DOCUMENT_KEYS.VSTUPNY, []), document]
			})
		case DOKUMENT_TYP.VYSTUPNY:
			return {
				...parsedDocuments,
				[DOCUMENT_KEYS.VYSTUPNY]: [...get(parsedDocuments, DOCUMENT_KEYS.VYSTUPNY, []), document]
			}
		case DOKUMENT_TYP.OSTATNE:
			return {
				...parsedDocuments,
				[DOCUMENT_KEYS.OSTATNE]: [...get(parsedDocuments, DOCUMENT_KEYS.OSTATNE, []), document]
			}
		case DOKUMENT_TYP.STORNO:
			return {
				...parsedDocuments,
				[DOCUMENT_KEYS.STORNO]: [...get(parsedDocuments, DOCUMENT_KEYS.STORNO, []), document]
			}
		case DOKUMENT_TYP.UKONCENY:
			return {
				...parsedDocuments,
				[DOCUMENT_KEYS.UKONCENY]: [...get(parsedDocuments, DOCUMENT_KEYS.UKONCENY, []), document]
			}
		default:
			return {}
	}
}

export const parseDocumentsForRequest = (dokumenty) => {
	if (!Array.isArray(dokumenty)) {
		return []
	}

	const onlyUploadedDocuments = dokumenty.filter((dokument) => typeof dokument?.id === 'string')

	const documentsForRequest = onlyUploadedDocuments.map((dokument) => {
		return {
			id: get(dokument, 'id')
		}
	})

	return documentsForRequest
}

export const uploadDocuments = async (documents, ukonId) => {
	try {
		const uploadPromises = documents.map((document) => {
			const requestBody = {
				contentType: get(document, 'type'),
				nazov: get(document, 'name'),
				data: get(document, 'dataAsBase64'),
				typ: get(document, 'dokumentTyp'),
				druh: get(document, 'dokumentDruh')
			}

			return postReq(`/api/v0/ukony/${ukonId}/prilozit-dokument`, null, requestBody)
		})

		const promiseAllResult = await Promise.all(uploadPromises)

		const uploadedDocuments = promiseAllResult.map((resultData) => {
			const uploadedDocument = resultData?.response

			return {
				id: get(uploadedDocument, 'id'),
				name: get(uploadedDocument, 'nazov'),
				type: get(uploadedDocument, 'contentType'),
				dokumentDruh: get(uploadedDocument, 'druh'),
				dokumentTyp: get(uploadedDocument, 'typ')
			}
		})

		return uploadedDocuments
	} catch (error) {
		console.error('Zlyhalo nahranie prílohy:', error)
		// throw error TODO: Ohandlovat zlyhanie nahravania suboru
	}
}

export const parseNewDocuments = (newDocuments) => {
	if (isEmpty(newDocuments)) return null

	return newDocuments.map((newDocument) => {
		return {
			id: get(newDocument, 'id'),
			name: get(newDocument, 'nazov'),
			type: get(newDocument, 'contentType'),
			dokumentDruh: get(newDocument, 'druh'),
			dokumentTyp: get(newDocument, 'typ')
		}
	})
}

export const scrollToFirstError = (errors) => {
	const firstError = Object.keys(errors)?.[0]
	const element = document.querySelector(`[name="${firstError}"]`)
	if (element) {
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'center'
		})
	}
}

export const generateUniqueId = (text) => {
	const formattedDate = new Date().toISOString()
	// NOTE: generate a random number to ensure uniqueness
	const uniqueNumber = Math.floor(Math.random() * 10000)

	// NOTE: create the unique ID by combining formattedDate, text, and unique number
	const uniqueId = `${formattedDate}_${text}_${uniqueNumber}`

	return uniqueId
}
