import React from 'react'
import { Field } from 'redux-form'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { size, upperFirst } from 'lodash'

// atoms
import { TextInputField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

class SplnomocnenecField extends React.Component {
	static propTypes = {
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.string,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape()
	}

	state = {}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { t } = this.props

		if (value && size(value) > 120) {
			return t('translation:Common.validate.Splnomocnenec môže mať najviac 120 znakov')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.TWO, t, value } = this.props

		const valueBefore = null
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.CREATE) {
			valueAfter = (
				<Field
					name={field}
					component={TextInputField}
					validate={this.validate}
					placeholder={t('translation:Common.Zadajte titul, meno a priezvisko splnomocnenca')}
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter = upperFirst(value)
		}

		return (
			<table className='content-table padded bordered' cellSpacing='0'>
				<tbody>
					<tr>
						<td>
							<strong>{t('translation:Common.Splnomocnenec')}</strong>
						</td>
						{columnsCount === COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
						<td>{valueAfter}</td>
					</tr>
				</tbody>
			</table>
		)
	}
}

export default compose(withTranslation('components'))(SplnomocnenecField)
