import React from 'react'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { map, get, upperFirst, isEmpty } from 'lodash'

// atoms
import { SelectField } from '../../../atoms'

// config
import { COLUMNS_COUNT, EDIT_MODE } from '../../../containers/GenericUkon/genericUkonConfig'

// utils
import { formatFormValue } from '../../../utils/form'
import { PREUKAZ_TYP } from '../../../utils/enums'

class PreukazTypField extends React.Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
		field: PropTypes.string.isRequired,
		editMode: PropTypes.string.isRequired,
		columnsCount: PropTypes.number,
		change: PropTypes.func.isRequired,
		formValues: PropTypes.shape(),
		preukazTyp: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				nazov: PropTypes.string.isRequired
			})
		),
		povinny: PropTypes.bool.isRequired,
		t: PropTypes.func.isRequired,
		value: PropTypes.shape(),
		originalValue: PropTypes.shape()
	}

	state = {
		produktyOptions: []
	}

	constructor(props) {
		super(props)
	}

	componentDidMount() {
		this._mounted = true

		const { preukazTyp } = this.props

		const preukazTypOptions = map(preukazTyp, (preukazTyp) => ({
			label: upperFirst(get(preukazTyp, 'nazov')),
			value: preukazTyp
		}))

		if (this._mounted) {
			this.setState({
				preukazTypOptions
			})
		}
	}

	componentDidUpdate(prevProps) {
		const { dispatch, field, change, formValues } = this.props

		if (get(prevProps, `formValues.${field}.id`) != get(formValues, `${field}.id`)) {
			if (get(formValues, `${field}.id`) == PREUKAZ_TYP.ISIC) {
				dispatch(change('data.preukazCislo', 'S421'))
			} else if (get(formValues, `${field}.id`) == PREUKAZ_TYP.ITIC) {
				dispatch(change('data.preukazCislo', 'T421'))
			} else if (get(formValues, `${field}.id`) == PREUKAZ_TYP.EURO26) {
				dispatch(change('data.preukazCislo', 'E421'))
			}
		}
	}

	componentWillUnmount() {
		this._mounted = false
	}

	validate = (value) => {
		const { povinny, t } = this.props

		if (povinny && !value) {
			return t('translation:Common.validate.Typ preukazu je povinný')
		}
	}

	render() {
		const { field, editMode, columnsCount = COLUMNS_COUNT.THREE, value, originalValue, t } = this.props
		const { preukazTypOptions } = this.state

		const valueBefore = upperFirst(get(originalValue, 'nazov'))
		let valueAfter = null

		if (editMode == EDIT_MODE.EDIT || editMode == EDIT_MODE.LOOKUP) {
			valueAfter = (
				<Field
					name={field}
					component={SelectField}
					options={preukazTypOptions}
					isSearchable={false}
					isDisabled={isEmpty(preukazTypOptions)}
					validate={this.validate}
					compare={(option, val) => {
						return get(option, 'value.id') == get(val, 'id')
					}}
					classNamePrefix='react-select'
				/>
			)
		} else if (editMode == EDIT_MODE.CONFIRM || editMode == EDIT_MODE.DETAIL) {
			valueAfter =
				columnsCount == COLUMNS_COUNT.THREE
					? formatFormValue(get(value, 'nazov'), get(originalValue, 'nazov'), upperFirst(get(value, 'nazov')))
					: upperFirst(get(value, 'nazov'))
		}

		return (
			<div className='inner-box'>
				<table className='content-table padded bordered' cellSpacing='0'>
					<tbody>
						<tr>
							<td>
								<strong>{t('translation:Common.Typ preukazu')}</strong>
							</td>
							{columnsCount == COLUMNS_COUNT.THREE && <td>{valueBefore}</td>}
							<td>{valueAfter}</td>
						</tr>
					</tbody>
				</table>
			</div>
		)
	}
}

const mapDispatchToProps = (dispatch) => ({
	dispatch
})

export default compose(withTranslation('components'), connect(null, mapDispatchToProps))(PreukazTypField)
